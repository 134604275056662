// ---------------
// Wrapper for main app where we load one external CSS and maybe service worker later on
// ---------------

import React from 'react';
import ReactDOM from 'react-dom';
import { I18nextProvider }  from 'react-i18next'

import App from './App';
import i18n from './Translations';
//import * as serviceWorker from './serviceWorker';

// Make the env variable survive deep enough for the app to use
window.API_HOST = process.env.REACT_APP_API_HOST;

if (process.env.NODE_ENV !== 'production') {
  window.localStorage.setItem('debug', 'suomikatsoo-react:*');
}

// TODO remove at some point, this is a "migration"
if (window.localStorage.getItem('myChannels')) {
  let c = JSON.parse(window.localStorage.getItem('myChannels'));
  window.localStorage.setItem('myChannels', JSON.stringify(c.map(x => x === 'ava' ? 'avahd' : x)));
  c = JSON.parse(window.localStorage.getItem('myChannels'));
  window.localStorage.setItem('myChannels', JSON.stringify(c.map(x => x === 'sub' ? 'mtvsubhd' : x)));
}

ReactDOM.render(
    <I18nextProvider i18n={ i18n }>
        <App />
    </I18nextProvider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
//serviceWorker.unregister();

// Remove any old service workers
if ('serviceWorker' in navigator) {
    navigator.serviceWorker.getRegistrations().then(function(registrations) {
    for(let registration of registrations) {
      registration.unregister()
    }}).catch(function(err) {
      console.log('Service Worker registration failed: ', err);
    });
}
